import React, { useState, useEffect, useRef } from "react";

import { Link } from "gatsby";
import ProjectPreview from "./project-preview";

import * as styles from "./project-preview-grid.module.css";
import { responsiveTitle0 } from "../components/typography.module.css";

function debounce(func, wait, immediate) {
  // console.log("debouncing");
  var timeout;

  return () => {
    var context = this,
      args = arguments;

    var later = () => {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    var callNow = immediate && !timeout;

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);

    if (callNow) func.apply(context, args);
  };
}

function ProjectPreviewGrid(props) {
  const allProjects = props.nodes;

  const [filteredProjects, setFilteredProjects] = useState(allProjects);

  const [list, setList] = useState([...allProjects.slice(0, 9)]);

  const [searchQuery, setSearchQuery] = useState("");

  // console.log(list);

  // State to trigger load more
  const [loadMore, setLoadMore] = useState(false);

  // State of whether there is more to load
  const [hasMore, setHasMore] = useState(filteredProjects.length > 9);

  // Load more button click
  const handleLoadMore = () => {
    setLoadMore(true);
  };

  //Set a ref for the loading div
  const loadRef = useRef();

  //Initialize the intersection observer API
  useEffect(() => {
    var options = {
      root: null,
      rootMargin: "20px",
      threshold: 1.0,
    };
    const observer = new IntersectionObserver(handleObserver, options);
    if (loadRef.current) {
      observer.observe(loadRef.current);
    }
  }, []);

  // Handle intersection with load more div
  const handleObserver = (entities) => {
    const target = entities[0];
    if (target.isIntersecting) {
      setLoadMore(true);
    }
  };

  // Handle loading more articles
  useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = list.length;
      const isMore = currentLength < filteredProjects.length;
      const nextResults = isMore ? filteredProjects.slice(currentLength, currentLength + 9) : [];

      setList([...list, ...nextResults]);
      setLoadMore(false);
    }
  }, [loadMore, hasMore]); //eslint-disable-line

  // Check if there is more
  useEffect(() => {
    const isMore = list.length < filteredProjects.length;
    setHasMore(isMore);
  }, [list]); //eslint-disable-line

  useEffect(() => {
    // console.log(searchQuery);
    if (searchQuery === "") {
      setFilteredProjects(allProjects);
      setList(allProjects.slice(0, 9));
      return;
    }

    let newFilteredProjects = allProjects.filter((project) => {
      if (project.title.toLowerCase().includes(searchQuery.toLowerCase())) {
        return true;
      }

      if (project.categories) {
        const categories = project.categories.map((category) => category.title);
        if (categories.join(" ").toLowerCase().includes(searchQuery.toLowerCase())) {
          return true;
        }
      }
      return false;
    });

    setFilteredProjects(newFilteredProjects);

    // setList(filteredProjects.slice(0, 9));
  }, [searchQuery]);

  useEffect(() => {
    debounce(() => {
      setList(filteredProjects.slice(0, 9));
    }, 200)();
  }, [filteredProjects]);

  return (
    <div className={styles.root}>
      {props.title && <h1 className={responsiveTitle0}>{props.title}</h1>}

      <div className={styles.searchContainer}>
        <input
          type="text"
          placeholder='Projektsuche (zB "Grundbuch" oder "Vue")'
          value={searchQuery}
          onChange={(e) => {
            setSearchQuery(e.target.value);
            // console.log(e.target.value);
          }}
          // onKeyUp={(e) => {
          //   setSearchQuery(e.target.value);
          // }}
          className={styles.searchInput}
        />
      </div>

      <ul className={styles.grid}>
        {list &&
          list.map((node) => (
            <li key={node.id}>
              <ProjectPreview {...node} />
            </li>
          ))}
      </ul>
      <div ref={loadRef}></div>
      {/* load more button */}
      {hasMore ? (
        <div className={styles.loadMoreBtnContainer}>
          <button onClick={handleLoadMore} className={styles.loadMoreBtn}>
            Mehr anzeigen
          </button>
        </div>
      ) : (
        ""
      )}
      {/* {props.browseMoreHref && (
        <div className={styles.browseMoreNav}>
          <Link to={props.browseMoreHref}>Mehr im Archiv...</Link>
        </div>
      )} */}
    </div>
  );
}

ProjectPreviewGrid.defaultProps = {
  title: "",
  nodes: [],
  browseMoreHref: "",
};

export default ProjectPreviewGrid;
