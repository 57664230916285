import { Link } from "gatsby";
import React from "react";
import { cn, buildImageObj } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";
import BlockContent from "./block-content";

import * as styles from "./project-preview.module.css";
import { responsiveTitle3 } from "./typography.module.css";

const catIcon = (cat) => {
  if (cat && cat.icon) {
    return (
      <img
        className={styles.catIcon}
        key={cat.id}
        src={imageUrlFor(buildImageObj(cat.icon))
          .ignoreImageParams()
          .width(40)
          .height(40)
          .fit("fill")
          .url()}
        alt={cat.icon.alt}
      />
    );
  }
};

const categories = (cat) => {
  if (cat == null) {
    return "";
  }
  let result = (
    <div className={styles.iconsContainer}>
      {cat.slice(0, 5).map((cat) => (
        <div key={cat.id}> {catIcon(cat)}</div>
      ))}
    </div>
  );

  return result;
};

function ProjectPreview(props) {
  return (
    <Link className={styles.root} to={`/project/${props.slug.current}`}>
      <div>
        <div className={styles.leadMediaThumb}>
          {props.mainImage && props.mainImage.asset && (
            <img
              className={styles.previewImage}
              src={imageUrlFor(buildImageObj(props.mainImage))
                .width(600)
                .height(Math.floor((9 / 16) * 600))
                .auto("format")
                .url()}
              alt={props.mainImage.alt}
            />
          )}
        </div>
        <h2 className={cn(responsiveTitle3, styles.title)}>{props.title}</h2>
      </div>
      {categories(props.categories)}
      {/* {props._rawExcerpt && (
        <div className={styles.excerpt}>
          <BlockContent blocks={props._rawExcerpt} />
        </div>
      )} */}
    </Link>
  );
}

export default ProjectPreview;
